import TYPES from '@/types';
import Vue from 'vue';
import { v4 } from 'uuid';

// Application
import GetRiskToleranceQuestionsQuery
  from '@/modules/flagship/risk-tolerance-questions/application/queries/get-risk-tolerance-questions-query';
import GetInvestmentProductFundTypeQuery
  from '@/modules/flagship/catalogs/investment-product-fund-type/application/queries/get-investment-product-fund-type-query';
import InvestorGoalRiskToleranceDtoService
  from '@/modules/flagship/investor-goal-risk-tolerance/application/services/investor-goal-risk-tolerance-dto-service';
import InvestorGoalRiskToleranceAnswersService
  from '@/modules/flagship/investor-goal-risk-tolerance/application/services/investor-goal-risk-tolerance-answers-service';
import InvestorGoalRiskToleranceChoseWarningAnswerService
  from '@/modules/flagship/investor-goal-risk-tolerance/application/services/investor-goal-risk-tolerance-chose-warning-answer-service';

// Domain
import { RiskToleranceQuestionsDto }
  from '@/modules/flagship/risk-tolerance-questions/domain/dtos/risk-tolerance-questions-dto';
import { Answer, RiskToleranceQuestionsEntity }
  from '@/modules/flagship/risk-tolerance-questions/domain/entities/risk-tolerance-questions-entity';
import { InvestorGoalRiskToleranceDto }
  from '@/modules/flagship/investor-goal-risk-tolerance/domain/dto/investor-goal-risk-tolerance-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type AnswerDto = {
  id: string;
  risk_tolerance_question_id: string;
  risk_tolerance_answer_id: string;
}

export default class StrategyRiskToleranceViewModel {
  @Inject(TYPES.GET_RISK_TOLERANCE_QUESTIONS_QUERY)
  private readonly get_risk_tolerance_questions_query!: GetRiskToleranceQuestionsQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCT_FUND_TYPE_QUERY)
  private readonly get_investment_product_fund_type_query!: GetInvestmentProductFundTypeQuery;

  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_DTO_SERVICE)
  private readonly investor_goal_risk_tolerance_dto_service!: InvestorGoalRiskToleranceDtoService;

  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_ANSWERS_SERVICE)
  private readonly investor_goal_risk_tolerance_answer_service!:
    InvestorGoalRiskToleranceAnswersService;

  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_CHOSE_WARNING_ANSWERS_SERVICE)
  private readonly investor_goal_risk_tolerance_chose_warning_answer_service!:
    InvestorGoalRiskToleranceChoseWarningAnswerService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly view: Vue;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.strategy-risk-tolerance';

  public constructor(view: Vue) {
    this.view = view;
  }

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly WARNING_ANSWERS_NAMES = ['5_percent_annualized_return', 'sell_immediately_and_never_invest_in_that_strategy_again', 'highly_likely']

  questions: Array<RiskToleranceQuestionsEntity> = []

  is_invalid_form = false;

  selected_answers: Array<Answer> = [];

  investor_goal_risk_tolerance_dto: InvestorGoalRiskToleranceDto = {
    customer_id: this.customer_id!,
    investor_goal_id: '',
    investment_product_fund_type_id: '',
    answers: [],
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }

  initialize = async (goal_name: string, investor_goal_id: string) => {
    this.view.$emit('setDynamicWidth', '50');
    this.setInitialInformation(investor_goal_id);
    await this.loadSowosModerateId();
    await this.loadRiskToleranceQuestions(goal_name);
  }

  getInvestorGoalRiskToleranceAnswers = () => (this.investor_goal_risk_tolerance_answer_service
    .getInvestorGoalRiskToleranceAnswers());

  setInvestorGoalRiskToleranceDto = () => {
    this.investor_goal_risk_tolerance_dto_service
      .setInvestorGoalRiskToleranceDto(this.investor_goal_risk_tolerance_dto);
  }

  setInvestorGoalRiskToleranceAnswers = () => {
    this.investor_goal_risk_tolerance_answer_service
      .setInvestorGoalRiskToleranceAnswers(this.selected_answers);
  }

  setInvestorGoalRiskToleranceChoseWarningAnswer = (user_chose_warning_answer: boolean) => {
    this.investor_goal_risk_tolerance_chose_warning_answer_service
      .setInvestorGoalRiskToleranceChoseWarningAnswer(user_chose_warning_answer);
  }

  setInitialInformation = (investor_goal_id: string) => {
    this.investor_goal_risk_tolerance_dto.investor_goal_id = investor_goal_id;
    const selected_answers = this.getInvestorGoalRiskToleranceAnswers();
    this.selected_answers = [...selected_answers];
  }

  loadRiskToleranceQuestions = async (goal_name: string) => {
    try {
      const payload: RiskToleranceQuestionsDto = {
        investment_product_id: this
          .investor_goal_risk_tolerance_dto.investment_product_fund_type_id,
      };
      const result = await this.get_risk_tolerance_questions_query.execute(payload);
      this.questions = result.map((question) => ({
        ...question,
        label: question.label.replaceAll('Nombre de meta', goal_name).replaceAll('Nombre meta', goal_name),
      }));
    } catch {
      this.message_notifier.showErrorNotification(this.translate('load_risk_tolerance_questions'));
    }
  }

  loadSowosModerateId = async () => {
    try {
      const fund_types = await this.get_investment_product_fund_type_query.execute();
      const investment_product_fund_type = fund_types.find(
        (type) => type.label === 'SWSMOD',
      );
      this.investor_goal_risk_tolerance_dto.investment_product_fund_type_id = investment_product_fund_type?.id || '';
    } catch {
      this.message_notifier.showErrorNotification(this.translate('load_investment_products'));
    }
  }

  verifyIfWarningChangeStrategyViewHasToBeShowed = () => {
    this.saveAnswersInTheState();
    const user_selected_a_warning_answer = this.selected_answers
      .some((answer) => this.WARNING_ANSWERS_NAMES.includes(answer.name));
    if (!user_selected_a_warning_answer) this.view.$emit('dontShowWarningChangeStrategy', true);
    this.setInvestorGoalRiskToleranceChoseWarningAnswer(user_selected_a_warning_answer);
    this.nextStep();
  }

  saveAnswersInTheState = () => {
    const answers_dto: Array<AnswerDto> = [];
    this.selected_answers.forEach((selected_answer) => {
      answers_dto.push({
        id: v4(),
        risk_tolerance_question_id: selected_answer.risk_tolerance_question_id,
        risk_tolerance_answer_id: selected_answer.id,
      });
    });
    this.investor_goal_risk_tolerance_dto.answers = [...answers_dto];
    this.setInvestorGoalRiskToleranceDto();
    this.setInvestorGoalRiskToleranceAnswers();
  }
}
