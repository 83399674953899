




















































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import StrategyRiskToleranceViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/hiring-flow/strategy-risk-tolerance-view-model';

@Component({ name: 'StrategyRiskTolerance' })
export default class StrategyRiskTolerance extends Vue {
  @PropSync('goalName', { type: String, required: true, default: '' })
  synced_goal_name!: string;

  @PropSync('investorGoalId', { type: String, required: true, default: '' })
  synced_investor_goal_id!: string;

  strategy_risk_tolerance_view_model = Vue.observable(
    new StrategyRiskToleranceViewModel(this),
  );

  async created() {
    await this.strategy_risk_tolerance_view_model
      .initialize(this.synced_goal_name, this.synced_investor_goal_id);
  }
}
